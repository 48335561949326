import { environment } from "../../environments/environment";

import { Address } from "./models/address";

export const API_URL: string = environment.API_URL;
export const API_TOKEN: string = environment.API_TOKEN;

export const APP: any = {

	NAME: 'asc-carrementbon',
	HOST: 'localhost',
	VERSION: '1.0.0',
	UUID: ''

}

export const SHOP_ADDRESSES: Address[] = [{

	firstName: "CARREMENT BON",
	lastName: "parking Sport 2000",
	street: "4 rue de la manoque",
	city: "Grenade",
	zip: "31330",
	phone: "+33 6 19 04 11 99",
	country: "FR",
	isBilledAddress: false,
	isDefaultBilledAddress: false,
	isDefault: false

}]